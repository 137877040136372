export const speech = {
    patient: [
        {
            id: 0,
            text:
                <>
                    Buongiorno Dottoressa, vorrei chiederle un consiglio. Da oltre un anno ho rapporti assidui con lo
                    stesso partner e vorrei evitare una gravidanza. Lei cosa mi suggerisce?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 0,
            }
        },
        {
            id: 1,
            text:
                <>
                    Sì, certo.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 2,
            }
        },
        {
            id: 2,
            text:
                <>
                    19.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 4,
            },
        },
        {
            id: 3,
            text:
                <>
                    58 chili.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 4,
            },
        },
        {
            id: 4,
            text:
                <>
                    Studio.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 6,
            },
        },
        {
            id: 5,
            text:
                <>
                    Non fumo. Sporadicamente, mi capita di bere qualche bicchiere di vino.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 6,
            },
        },
        {
            id: 6,
            text:
                <>
                    Un metro e 67.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 9,
            },
        },
        {
            id: 7,
            text:
                <>
                    Non pratico nessuna attività fisica, ammetto di fare una vita piuttosto sedentaria.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 8,
            },
        },
        {
            id: 8,
            text:
                <>
                    A 12 anni.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 11,
            },
        },
        {
            id: 9,
            text:
                <>
                    Sì.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 11,
            },
        },
        {
            id: 10,
            text:
                <>
                    I cicli sono regolari, ma le mestruazioni sono molto dolorose.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 13,
            },
        },
        {
            id: 11,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 13,
            },
        },
        {
            id: 12,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 16,
            },
        },
        {
            id: 13,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 16,
            },
        },
        {
            id: 14,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 16,
            },
        },
        {
            id: 15,
            text:
                <>
                    Oltre alla pillola esistono altri metodi contraccettivi ormonali?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 17,
            },
        },
        {
            id: 16,
            text:
                <>
                    Hanno controindicazioni?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 18,
            },
        },
        {
            id: 17,
            text:
                <>
                    È vero che l’uso della pillola può provocare sanguinamenti intraciclo o determinare un aumento di
                    peso?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 19,
            },
        },
        {
            id: 18,
            text:
                <>
                    La ringrazio dottoressa, ci penserò.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 20,
            },
        },
    ],
    doctor: [
        {
            id: 0,
            text:
                <>
                    Esistono diversi metodi contraccettivi. Tra questi, quelli reversibili sono generalmente distinti
                    in: ormonali e non ormonali, come il profilattico o il diaframma. I contraccettivi ormonali si
                    dividono in quelli a lunga durata d’azione, come i device intrauterini, e a breve durata d’azione,
                    come le pillole anticoncezionali.<sup>1</sup>
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 1,
            },
        },
        {
            id: 1,
            text:
                <>
                    Parliamone meglio. Inizierò a farle qualche domanda per capire quali sono le sue esigenze e le sue
                    abitudini quotidiane in modo da avere un quadro completo della sua storia clinica. È d’accordo?
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 1,
            },
        },
        {
            id: 2,
            text:
                <>
                    Quanti anni ha?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 2,
            },
        },
        {
            id: 3,
            text:
                <>
                    Quanto pesa?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 3,
            },
        },
        {
            id: 4,
            text:
                <>
                    Studia o lavora?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 4,
            },
        },
        {
            id: 5,
            text:
                <>
                    Fuma, beve?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 5,
            },
        },
        {
            id: 6,
            text:
                <>
                    Quanto è alta?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 6,
            },
        },
        {
            id: 7,
            text:
                <>
                    Pratica attività fisica o conduce una vita piuttosto sedentaria?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 7,
            },
        },
        {
            id: 8,
            text:
                <>
                    Condurre una vita sedentaria può rappresentare un fattore di rischio per il tromboembolismo
                    venoso.<sup>2</sup>
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 9,
            },
        },
        {
            id: 9,
            text:
                <>
                    Quando ha avuto la prima mestruazione?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 8,
            },
        },
        {
            id: 10,
            text:
                <>
                    Misuriamo la pressione. Generalmente è nella norma?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 9,
            },
        },
        {
            id: 11,
            text:
                <>
                    Parliamo del ciclo mestruale. I cicli sono regolari? Ha flussi abbondanti o mestruazioni dolorose?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 10,
            },
        },
        {
            id: 12,
            text:
                <>
                    Ha allergie a cibi?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 11,
            },
        },
        {
            id: 13,
            text:
                <>
                    È allergica a qualche farmaco e/o molecola?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 12,
            },
        },
        {
            id: 14,
            text:
                <>
                    Soffre di qualche malattia cronica, come il diabete mellito?
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 13,
            },
        },
        {
            id: 15,
            text:
                <>
                    Si è sottoposta a qualche intervento chirurgico, in passato?
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 14,
            },
        },
        {
            id: 16,
            text:
                <>
                    Perfetto, grazie. Ha mai usato o pensato di usare un metodo contraccettivo ormonale?<br/>
                    I contraccettivi ormonali contengono un progestinico, che può essere associato o meno ad un
                    estrogeno.<sup>1</sup> Tra questi, i regimi monofasici, ovvero quelli in cui ogni pillola ha lo
                    stesso dosaggio di ormoni, hanno significativi vantaggi rispetto ai regimi bi- e trifasici. Nel
                    primo caso, i cicli possono essere estesi facilmente saltando la settimana di placebo e iniziando la
                    successiva confezione con farmaco contraccettivo.<sup>1</sup>
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 15,
            },
        },
        {
            id: 17,
            text:
                <>
                    Sì, i metodi che contengono sia estrogeno che progestinico includono, oltre alla pillola a
                    somministrazione orale quotidiana, l’anello vaginale mensile e il cerotto transdermico
                    settimanale.<sup>1</sup>
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 16,
            },
        },
        {
            id: 18,
            text:
                <>
                    Uno dei rischi associato all’utilizzo della contraccezione ormonale combinata è un aumento
                    estrogeno-mediato degli eventi di trombosi venosa.<sup>1</sup> I cerotti transdermici e l’anello
                    vaginale sembrano essere associati a un maggior rischio di trombosi venosa rispetto alla
                    contraccezione orale con progestinico (Levonorgestrel).<sup>2</sup> Per diminuire il rischio di
                    trombosi, la dose di estrogeni nei contraccettivi orali combinati è stata gradualmente ridotta nel
                    corso degli anni.<sup>3</sup>
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 17,
            },
        },
        {
            id: 19,
            text:
                <>
                    I sanguinamenti tra un ciclo e l’altro si presentano principalmente nel primo ciclo nel 31,4% dei
                    casi e si riducono in modo significativo nei cicli successivi.<sup>4</sup> Uno dei timori più
                    diffusi è che la pillola faccia ingrassare. In quanto farmaco estroprogestinico può favorire in
                    parte il fenomeno della ritenzione idrica, ma i dosaggi sono talmente ridotti che potrebbe
                    trattarsi, qualora si verifichi, di ritenzione idrica modesta e solo per i primi mesi di assunzione.<sup>5</sup>
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 18,
            },
        },
        {
            id: 20,
            text:
                <>
                    Grazie a lei.
                </>,
            type: "text",
        },
    ],
}
