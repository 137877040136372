import ChatBox from "../partials/chatbox/ChatBox";
import {useContext, useEffect, useRef} from "react";
import {AppContext, components} from "../../reducer/contexts";
import {actionTypes} from "../../reducer/actionTypes";
import patient from "../../assets/images/patient_chat.png";
import doctor from "../../assets/images/doctor_chat.png";
import BlueButton from "../partials/BlueButton";
import logo from "../../assets/images/logo.png";
import {speech} from "../../utilis/speech";
import {chatTimeout} from "../../utilis/common";

const Chat = () => {
    const {state, dispatch} = useContext(AppContext);
    const endChatRef = useRef();

    useEffect(() => {
        dispatch({type: actionTypes.START_STOP_PLAYING});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.isPlaying) {
            dispatch({
                type: actionTypes.ADD_MESSAGE_TO_CHAT,
                payload: {
                    subject: "patient",
                    index: 0
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isPlaying]);

    useEffect(() => {
        endChatRef.current.scrollIntoView({behavior: "smooth"});
        setTimeout(() => endChatRef.current.scrollIntoView({behavior: "smooth"}), chatTimeout + 100);
    }, [state.chat]);

    useEffect(() => {
        if (!state.isPlaying) {
            endChatRef.current.scrollIntoView({behavior: "smooth"});
        }

    }, [state.isPlaying]);

    const handlePlayAgainBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.HOME,
    });

    const handleCloseBtnClick = () => {
        dispatch({type: actionTypes.START_STOP_PLAYING});

        dispatch({
            type: actionTypes.SET_ACTIVE_COMPONENT,
            payload: components.HOME,
        });
    }

    return (
        <div className="bg-white border-[20px] border-t-[50px] lg:border-[1.3vw] border-sea-green-dark w-full h-full">
            <div
                className="flex lg:border-[1vw] border-sea-green-light w-full h-full">

                <img src={logo} className="lg:hidden absolute top-[10px] h-[30px]" alt="logo pfzer"/>

                <BlueButton icon="home"
                            className="absolute top-[5px] lg:top-auto lg:bottom-[7%] right-[20px] lg:right-[4%] max-sm:!w-[40px] max-sm:!h-[40px] z-10"
                            onClick={handleCloseBtnClick}/>

                <div className="relative hidden lg:block w-[20%]">
                    <div className="flex justify-center">
                        <img src={patient} className="absolute -top-[4%] w-patient-doctor-icon" draggable={false}
                             alt="patient icon"/>
                    </div>
                </div>

                <div className="flex flex-col w-full lg:w-[60%] px-4 my-4 lg:my-10 overflow-y-scroll">
                    {state.chat?.map((chat, index) => chat &&
                        <div key={index}>
                            <div className={`flex ${chat.subject === "doctor" && "justify-end"} w-full`}>
                                <ChatBox subject={chat.subject} index={chat.index}/>
                            </div>

                            {(speech[chat.subject][chat.index].type !== "question" || chat.chosen) &&
                                <div
                                    className={`lg:hidden flex ${chat.subject === "patient" ? "justify-start" : "justify-end"} w-full mt-14 mb-7`}>
                                    {chat.subject === "patient" &&
                                        <img src={patient} className="w-[80px]" alt="patient icon"/>}
                                    {chat.subject === "doctor" &&
                                        <img src={doctor} className="w-[80px] float-right" alt="doctor icon"/>}
                                </div>
                            }
                        </div>
                    )}

                    {!state.isPlaying &&
                        <div className="flex justify-center mt-5">
                            <BlueButton text="PROVA DI NUOVO" icon="play" className="!w-full lg:!w-[60%] !h-full"
                                        onClick={handlePlayAgainBtnClick}/>
                        </div>
                    }

                    <div ref={endChatRef}/>
                </div>

                <div className="relative hidden lg:block w-[20%]">
                    <div className="flex justify-center">
                        <img src={doctor} className="absolute top-[10%] w-patient-doctor-icon" draggable={false}
                             alt="doctor icon"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat;
