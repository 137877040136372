import bg from "../../assets/images/bg_info.png";
import BlueButton from "../partials/BlueButton";
import {AppContext, components} from "../../reducer/contexts";
import {useContext} from "react";
import {actionTypes} from "../../reducer/actionTypes";
import top from "../../assets/images/top_info.png";
import bottom from "../../assets/images/bottom_info.png";
import doctor from "../../assets/images/doctor.png";
import styles from "../../styles/References.module.scss";

const Info = () => {
    const {dispatch} = useContext(AppContext);
    const handleCloseBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.HOME
    });

    return (
        <div className="flex justify-center lg:justify-start bg-white lg:bg-transparent h-full">
            <img src={top} className="lg:hidden absolute top-0 -scale-x-100 w-full h-borer-info" alt="top border"/>
            <img src={bottom} className="lg:hidden absolute bottom-0 -scale-x-100 w-full h-borer-info"
                 alt="bottom border"/>

            <img src={doctor} className="lg:hidden absolute bottom-0 right-[-45px] h-[300px]" alt="doctor"/>

            <img src={bg} className="hidden lg:block background" draggable={false} alt="background"/>

            <BlueButton icon="home" className="absolute top-[30px] lg:top-[5%] right-[5%] lg:left-[5%]"
                        onClick={handleCloseBtnClick}/>

            <div
                className="w-full lg:w-[29%] px-4 mt-[150px] lg:mt-[12%] lg:ms-[28%]">
                <p className="text-3xl lg:text-lg 2xl:text-3xl text-sea-green-dark font-bold">RIFERIMENTI<br className="lg:hidden"/> BIBLIOGRAFICI</p>

                <div id={styles.textContainer}
                     className="overflow-auto mt-8 lg:mt-[10%] lg:!h-[70%]">
                    <p className="xl:text-lg 2xl:text-xl pb-[180px] lg:pb-0">
                        <ol className="list-decimal list-inside">
                            <li>
                                <span className="ps-5">
                                    Teal S and Edelman A. Contraception selection, effectiveness, and Adverse effects. A
                                    review (2021) JAMA 326(24):2507 – 2518
                                </span>
                            </li>
                            <li className="mt-3">
                                <span className="ps-5">
                                    Crous-Bou et al., Environmental and genetic risk factors associated with venous
                                    thromboembolism (2017) Semin Thromb Hemost doi: 10.1055/s-0036-1592333
                                </span>
                            </li>
                            <li className="mt-3">
                                <span className="ps-5">
                                    Van Hylckama A. et al., The venous thrombotic risk of oral contraceptives, effetcs of
                                    oestrogen dose and progestogen type: results of the MEGA case-contro study. (2009) BMJ
                                    doi: 10.1136/bmj.b2921
                                </span>
                            </li>
                            <li className="mt-3">
                                <span className="ps-5">
                                    Hite R. C. et al., Large observational trial of a new low-dose oral contraceptive
                                    containing 20 mg ethinylestradiol and 100 mg levonorgestrel (Miranova) in Germany (1999)
                                    Eur J Contracept Reprod Health Care; 4(1):7-13
                                </span>
                            </li>
                            <li className="mt-3">
                                <span className="ps-5">
                                    Humanitas. Pillola anticoncezionale, i miti da sfatare.
                                    https://www.humanitas.it/news/pillola-miti-sfatare/
                                </span>
                            </li>

                        </ol>
                    </p>
                </div>

            </div>
        </div>
    );
}

export default Info;
